import React, { Fragment } from "react"

import Layout from "layout/Layout"
import Container from "layout/Container"
import ContactUs from "./HelpCenter/ContactUs"
import Collapsible from "elements/Collapsible"

import { isBrowser } from "services/general"

const HelpCenter = props => {
  const { helpCenterContent, module } = props.pageContext

  let contactUsInclusions = ["fullName", "email", "inquiry", "message"]
  if (module.app === "giotrif")
    contactUsInclusions = ["fullName", "email", "mobile", "inquiry"]

  let url = ""
  if (isBrowser()) url = window.location.href

  let addition = (
    <span>
      <br className="is-hidden-mobie" />
      for Afatinib (Giotrif)
    </span>
  )
  if (!url.includes("giotrif")) {
    addition = (
      <span>
        <br className="is-hidden-mobie" />
        for Nintedanib (Ofev)
      </span>
    )
  }

  return (
    <Layout seoTitle={module.seoTitle}>
      <Container isCentered>
        <h2>Frequently Asked Questions {addition}</h2>
        <hr />
        {helpCenterContent.map(section => {
          return (
            <Fragment>
              <h4>{section.header}</h4>
              {section.fields.map(faq => {
                return (
                  <Collapsible title={faq.question}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: faq.answer.childMarkdownRemark.html.replace(
                          /\n/g,
                          "<br />"
                        ),
                      }}
                    ></p>
                  </Collapsible>
                )
              })}
            </Fragment>
          )
        })}
        <ContactUs inclusions={contactUsInclusions} app={module.app} />
      </Container>
    </Layout>
  )
}

export default HelpCenter
